import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { object } from 'yup';
import { CREATE_USER, UPDATE_USER } from '../../../redux/types/user.types';
import InputField from '../../shared/RHF/InputField';
import styles from './user.module.scss';
import { formVolunteerSchema, YupVolunteerSchema } from './Volunteers/helper';

const GenericUserForm = ({ type, handleClose, editUserData }) => {

    const dispatch = useDispatch();
    const isEditMode = !!Object.keys(editUserData).length; // Determine if we are in edit mode
    let userSchema = object(YupVolunteerSchema(isEditMode));

    const { control, handleSubmit, formState: { errors, isValid }, reset } = useForm(formVolunteerSchema(userSchema));

    const handleCreateUser = (data) => {
        data.role = type;
        console.log("data", data)
        if (isEditMode) {
            dispatch({ type: UPDATE_USER, payload: data });
        } else {
            dispatch({ type: CREATE_USER, payload: data });
        }
        handleClose();
    }

    useEffect(() => {
        if (isEditMode) {
            reset(editUserData);
        }
    }, [editUserData, reset, isEditMode]);

    return (
        <form className={styles.formContainer} onSubmit={handleSubmit(handleCreateUser)}>
            <Grid spacing={3} container>
                <Grid item xs={6}>
                    <InputField control={control} errors={errors} name={'firstName'} placeHolder={'First Name'} required={true} label={"First Name"} type={'text'} />
                </Grid>
                <Grid item xs={6}>
                    <InputField control={control} errors={errors} name={'lastName'} placeHolder={'Last Name'} required={true} label={"Last Name"} type={'text'} />
                </Grid>
                <Grid item xs={6}>
                    <InputField control={control} errors={errors} name={'mobileNum'} placeHolder={'Mobile Number'} label={"Mobile Number"} type={'text'} />
                </Grid>
                <Grid item xs={6}>
                    <InputField control={control} errors={errors} name={'email'} placeHolder={'Email'} required={true} label={"Email"} type={'text'} />
                </Grid>

                <Grid item xs={6}>
                    <InputField control={control} errors={errors} name={'password'} placeHolder={'Password'} required={!isEditMode} label={"Password"} type={'password'} />
                </Grid>

                <Grid item xs={6}>
                    <InputField control={control} errors={errors} name={'address'} placeHolder={'Address'} label={"Address"} type={'text'} />
                </Grid>
                <Grid item xs={6}>
                    <InputField control={control} errors={errors} name={'postCode'} placeHolder={'Postcode'} label={"Postcode"} type={'text'} />
                </Grid>
                <Grid display={'flex'} justifyContent={'flex-end'} item xs={12}>
                    <Button
                        sx={{ p: 1, px: 3 }}
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        type='submit'
                        disabled={!isValid}
                    >
                        {isEditMode ? "Update User" : "Create User"}
                    </Button>
                </Grid>

            </Grid>
        </form>
    )
}

export default GenericUserForm;
