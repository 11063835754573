import { Cancel, CheckCircle, CurrencyPound, Delete, Edit, MoreVert, PersonAdd, Store } from "@mui/icons-material";
import { Fade, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import theme from "../../shared/theme/theme";
import { formatDate, formatPaidDate } from "../../utils/date";
import { USER_ROLES } from "../../utils/enums";
import styles from './user.module.scss';
export const getMngmtItems = (role) => {
    switch (role) {
        case USER_ROLES.S_ADMIN:
            return [
                { label: "Trustees" },
                { label: "Volunteers" },
                { label: "Members" },
            ];
        case USER_ROLES.TRUSTEE:
            return [
                { label: "Volunteers" },
                { label: "Members" },
            ];
        case USER_ROLES.VOLUNTEER:
            return [
                { label: "Members" },
            ];
        default:
        ///dfd

    }

}


export const userReportItems = [
    { label: "Visited" },
    { label: "Served" },
];

export const genericUserMenuActions = [
    { label: 'Edit User', icon: <Edit color={theme.palette.primary.main} />, key: 'Edit' },
    { label: 'Delete User', icon: <Delete color='error' />, key: 'Delete' },
];

export const memberMenuActions = [
    { label: 'Add Subscription', icon: <CurrencyPound color={theme.palette.primary.main} />, key: 'Subscription' },
    { label: 'Edit Member', icon: <Edit color={theme.palette.primary.main} />, key: 'Edit' },
    // { label: 'Add Visit Manually', icon: <Store color={theme.palette.primary.main} />, key: 'Edit' },
    { label: 'Activate', icon: <PersonAdd color={theme.palette.primary.main} />, key: 'Activate' },
    { label: 'Delete Member', icon: <Delete color='error' />, key: 'Delete' },
];

export const memberMenuActionsDeactivate = [
    { label: 'Add Subscription', icon: <CurrencyPound color={theme.palette.primary.main} />, key: 'Subscription' },
    { label: 'Edit Member', icon: <Edit color={theme.palette.primary.main} />, key: 'Edit' },
    { label: 'Add Visit Manually', icon: <Store color={theme.palette.primary.main} />, key: 'Visit' },
    { label: 'Deactivate', icon: <PersonAdd color={theme.palette.primary.main} />, key: 'Deactivate' },
    { label: 'Delete Member', icon: <Delete color='error' />, key: 'Delete' },
];

export const getGenericUserColumns = ({ menuState, handleClose, handleClickItem, handleOpenMenu }) => {
    return [
        {
            field: "firstName",
            headerName: "First Name",
            width: 150,
        },
        {
            field: "lastName",
            headerName: "Last Name",
            width: 150,
        },
        {
            field: "email",
            headerName: "Email",
            width: 200,
        },
        {
            field: "mobileNum",
            headerName: "Mobile Number",
            width: 150,
        },
        {
            field: "createdAt",
            headerName: "Created At",
            width: 120,
            renderCell: (params) => formatDate(params.row.createdAt)
        },
        {
            field: "address",
            headerName: "Address",
            width: 200,
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 100,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => {
                return (
                    <>
                        <IconButton onClick={(event) => handleOpenMenu(event, params.row)} aria-label="More vert">
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={menuState.anchorEl}
                            open={Boolean(menuState.anchorEl)}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {genericUserMenuActions?.map((item, idx) => {
                                return (
                                    <MenuItem key={idx} className={styles.menuItem} onClick={() => handleClickItem(item?.key, menuState.selectedRow)}>
                                        {item?.icon}{item.label}
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </>
                )
            }
        }
    ]
}

export const genericUserDownloadCols = [
    {
        headerName: "First Name",
        field: "firstName",
        type: "text"
    },
    {
        field: "lastName",
        headerName: "Last Name",
        type: "text"
    },
    {
        field: "email",
        headerName: "Email",
        type: "text"
    },
    {
        field: "mobileNum",
        headerName: "Mobile Number",
        type: "text"
    },
    {
        field: "createdAt",
        headerName: "Registered On",
        type: "date"
    },
    {
        field: "address",
        headerName: "Address",
        type: "text"
    },
    {
        field: "postCode",
        headerName: "Post Code",
        type: "text"
    }
]


export const getMemberColumns = ({ menuState, handleClose, handleClickItem, handleOpenMenu }) => {
    return [
        {
            field: "forenameMember",
            headerName: "First Name",
            width: 150,
        },
        {
            field: "surnameMember",
            headerName: "Last Name",
            width: 150,
        },
        {
            field: "email",
            headerName: "Email",
            width: 150,
        },
        {
            field: "mobileNum",
            headerName: "Mobile Number",
            width: 150,
        },
        {
            field: "cardAllocated",
            headerName: "Card Allocated",
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        {params.row.cardNumber ? (
                            <Tooltip title="Card Allocated">
                                <CheckCircle style={{ color: theme.palette.success.main }} />
                            </Tooltip>
                        ) : (
                            <Tooltip title="No Card Allocated">
                                <Cancel style={{ color: theme.palette.error.main }} />
                            </Tooltip>
                        )}                </>
                )
            },
        },
        {
            field: "isActive",
            headerName: "Is Active",
            width: 100,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.userActive ? (
                            <Tooltip title="User is Active">
                                <CheckCircle style={{ color: theme.palette.success.main }} />
                            </Tooltip>
                        ) : (
                            <Tooltip title="User is Inactive">
                                <Cancel style={{ color: theme.palette.error.main }} />
                            </Tooltip>
                        )}                </>
                )
            },
        },

        {
            field: "visitCounts",
            headerName: "Visits This Week",
            width: 160,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: "totalNumberOfPeople",
            headerName: "No of Household",
            width: 160,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: "lastVisited",
            headerName: "Last Visited",
            width: 180,
            align: 'center',
            renderCell: (params) => formatPaidDate(params?.row?.lastVisitDate)
        },
        {
            field: "subscriptionPaid",
            headerName: "Subscription Paid",
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => formatPaidDate(params?.row?.subscription?.paidDate)

        },
        {
            field: "actions",
            headerName: "Actions",
            width: 100,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => {
                const isUserActive = params?.row?.userActive;
                return (
                    <>
                        <IconButton onClick={(event) => handleOpenMenu(event, params.row)} aria-label="More vert">
                            <MoreVert />
                        </IconButton>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={menuState.anchorEl}
                            open={Boolean(menuState.anchorEl) && menuState.selectedRow?._id === params?.row?._id}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {isUserActive ? (
                                memberMenuActionsDeactivate?.map((item, idx) => (
                                    <MenuItem
                                        key={idx}
                                        className={styles.menuItem}
                                        onClick={() => handleClickItem(item?.key, params.row)}
                                    >
                                        {item?.icon}{item.label}
                                    </MenuItem>
                                ))
                            ) : (
                                memberMenuActions?.map((item, idx) => (
                                    <MenuItem
                                        key={idx}
                                        className={styles.menuItem}
                                        onClick={() => handleClickItem(item?.key, params.row)}
                                    >
                                        {item?.icon}{item.label}
                                    </MenuItem>
                                ))

                            )}
                        </Menu>
                    </>
                );
            }
        }
    ];

}

export const memberDownloadCols = [
    {
        headerName: "First Name",
        field: "forenameMember",
        type: "text"
    },
    {
        field: "surnameMember",
        headerName: "Last Name",
        type: "text"
    },
    {
        field: "email",
        headerName: "Email",
        type: "text"
    },
    {
        field: "mobileNum",
        headerName: "Mobile Number",
        type: "text"
    },
    {
        field: "dobMember",
        headerName: "Date of Birth",
        type: "date"
    },
    {
        field: "address",
        headerName: "Address",
        type: "text"
    },
    {
        field: "postCode",
        headerName: "Post Code",
        type: "text"
    },
    {
        field: "isPropertyHouse",
        headerName: "Is Property a House",
        type: "text"
    },
    {
        field: "cardNumber",
        headerName: "Card Number",
        type: "text"
    },
    {
        field: "userActive",
        headerName: "Is Active",
        type: "boolean"
    },
    {
        field: "visitCounts",
        headerName: "Visits This Week",
        type: "text"
    },
    {
        field: "totalNumberOfPeople",
        headerName: "No of Household",
        type: "text"
    },
    {
        field: "allergyDetails",
        headerName: "Allergy Details",
        type: "text"
    },
    {
        field: "totalNumberOfPets",
        headerName: "No of Pets",
        type: "text"
    },
    {
        field: "visitCounts",
        headerName: "Visits this week",
        type: "text"
    },
    {
        field: "lastVisitDate",
        headerName: "Last Visited",
        type: "date"
    },
    {
        field1: "subscription",
        field2: "paidDate",
        headerName: "Subscription Paid",
        type: "date"
    },
    {
        field: "forenameShopper",
        headerName: "Shopper First Name",
        type: "text"
    },
    {
        field: "surnameShopper",
        headerName: "Shopper Last Name",
        type: "text"
    }
]



export const customTableStyles = {
    border: 0,
    "& .MuiDataGrid-row:nth-of-type(even)": {
        // backgroundColor: theme.palette.gray.light,
    },
    "& .MuiDataGrid-columnHeaders": {
        fontSize: "14px",
        fontWeight: 500,
        backgroundColor: theme.palette.primary.main,
    },
    "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
        fontWeight: "bolder",
    },
    "& .MuiDataGrid-cell": {
        cursor: "pointer",
        paddingLeft: "20px",
    },
    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.gray.main}`,
};