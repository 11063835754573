import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { authActions } from '../../redux/slice/auth.slice'
import Layout from './Layout'


const PrivateLayoutRoutes = () => {
  const auth = useSelector(authActions.selectors.getLoggedInStatus)


  return (
    auth ?
      <>
        <Layout>
          <Outlet />
        </Layout>
      </>
      : <Navigate to='/login' />
  )
}

export default PrivateLayoutRoutes;