import { formatPaidDate } from "./date";

export const generateCsv = (rows, columns) => {
    const headers = columns.map((col) => col.headerName).join(",");
    const dataRows = rows.map((row) =>
        columns.map((col) => {
            if (col.type === "boolean") {
                return row[col.field] ? "Yes" : "No";
            }
            else if (col.type === "date") {
                if (row[col.field]) {
                    return formatPaidDate(row[col.field])
                } else {
                    return formatPaidDate(row[col.field1]?.[col.field2])
                }
            }
            return row[col.field] || row[col.field1]?.[col.field2] || "-";
        }).join(",")
    );
    return [headers, ...dataRows].join("\n");
};

export const handleDownloadCsv = (rows, columns, name) => {
    const csvContent = generateCsv(rows, columns);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", name);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};