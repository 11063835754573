import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMngmtItems } from '../../components/modules/user/helper';
import Member from '../../components/modules/user/Member/Member';
import Trustee from '../../components/modules/user/Trustee/Trustee';
import Volunteers from '../../components/modules/user/Volunteers/Volunteers';
import CustomNavTabs from '../../components/shared/NavTabs/CustomNavTabs';
import { USER_ROLES } from '../../components/utils/enums';
import { authActions } from '../../redux/slice/auth.slice';
import { uiActions } from '../../redux/slice/ui.slice';

const UserManagement = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(uiActions.actions.setSelectedNav("User Management"));
    }, [dispatch])

    const selectedTab = useSelector(uiActions.selectors.getUserMangementTab)
    const handleSelectedTab = (value) => {
        dispatch(uiActions.actions.setUserMangementTab(value));
    };

    const currentUser = useSelector(authActions.selectors.getCurrentUser);





    const renderUserComponentAdmin = (selected) => {
        switch (selected) {
            case 0:
                return <Trustee />
            case 1:
                return <Volunteers />
            case 2:
                return <Member />
            default:
            //Do something
        }
    }

    const renderUserComponentTrustee = (selected) => {
        switch (selected) {
            case 0:
                return <Volunteers />
            case 1:
                return <Member />
            default:
            //Do something
        }
    }
    const renderUserComponentVolunteer = (selected) => {
        switch (selected) {
            case 0:
                return <Member />
            default:
            //Do something
        }
    }

    const renderUserComponents = (role, selectedTab) => {
        switch (role) {
            case USER_ROLES.S_ADMIN:
                return renderUserComponentAdmin(selectedTab)
            case USER_ROLES.TRUSTEE:
                return renderUserComponentTrustee(selectedTab)
            case USER_ROLES.VOLUNTEER:
                return renderUserComponentVolunteer(selectedTab)
            default:
            //Do something
        }
    }
    return (
        <>
            <CustomNavTabs selectedTab={selectedTab} handleSelectedTab={handleSelectedTab} navitems={getMngmtItems(currentUser?.role)} />
            <br />
            {renderUserComponents(currentUser?.role, selectedTab)}

        </>
    )
}

export default UserManagement
