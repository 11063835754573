import { Button, FormHelperText, Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

const ImageUpload = ({ control, name, errors, label, index }) => {
    const handleFileChange = (event, onChange) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                onChange({
                    file,
                    previewUrl: reader.result,
                });
            };
            reader.readAsDataURL(file);
        } else {
            onChange(null); // Clear if no file is selected
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <form>
                    <label>{label}</label>
                    <input
                        accept="image/*"
                        id={`contained-button-file-${index}`} // Unique ID
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(event) => handleFileChange(event, onChange)}
                    />
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <label htmlFor={`contained-button-file-${index}`}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    fullWidth
                                >
                                    {label}
                                </Button>
                            </label>
                        </Grid>
                        {value && value.previewUrl && (
                            <Grid item xs={12}>
                                <div>
                                    <img
                                        src={value.previewUrl}
                                        alt="Preview"
                                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                                    />
                                </div>
                            </Grid>
                        )}
                    </Grid>

                    {errors[name] && (
                        <FormHelperText error>{errors[name]?.message}</FormHelperText>
                    )}
                </form>
            )}
        />
    );
};

export default ImageUpload;
